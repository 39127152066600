import axios from 'axios';
import qs from 'qs';

export class Form {
    constructor(formElement, validationConfiguration, functions) {
        this.el = formElement;
        this.validationConfiguration = validationConfiguration;
        this.functions = functions ?? {};

        this.sendTime = 0

        //  Attach validation to custom change/blur event
        Array.from(this.el.querySelectorAll('[data-field-name]')).map(field => {
            const validator = this.validationConfiguration[field.dataset.fieldName];
            if (validator) {
                field.addEventListener(validator.triggerEvent || 'field-blur', (event) => {
                    this.validateField(validator, field.dataset.fieldName, event.detail.value);
                });
            }
        });

        this.el.addEventListener('submit', event => this.submit.bind(this)(event));
    }

    validateField(validator, fieldName, fieldValue) {
        let error = validator.validators
            .filter(validator => !validator.validate(fieldValue))
            .map(validator => validator.message)[0];

        let field = this.el.querySelector(`[data-field-name="${fieldName}"]`);
        if (field) {
            let errorPlaceholder = this.el.querySelector(`[data-error-for="${fieldName}"]`);
            if (errorPlaceholder) {
                errorPlaceholder.querySelector('.a-error__text').innerText = error;
                if (error) {
                    errorPlaceholder.classList.add('is-visible')
                    field.classList.add('has-error')
                } else {
                    errorPlaceholder.classList.remove('is-visible')
                    field.classList.remove('has-error')
                }
            } else {
                console.error(`Error placeholder for ${fieldName} not found!`);
            }
            return !!error;
        } else {
            console.error('Field ' + fieldName + ' not found!')
            return false;
        }
    }

    submit(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        let errorCount = 0

        const stockForm = document.querySelector('.js-stock__form');

        // get filters data if stock page
        if(stockForm) {
            const stockInputRange = stockForm.querySelector('.js-stock__input-range');
            const stockFormFormData = new FormData(stockForm);

            const initialPriceMin = stockInputRange.dataset.min;
            const initialPriceMax = stockInputRange.dataset.max;

            for (const pair of stockFormFormData.entries()) {
                if((pair[0].endsWith('_search')) || (pair[0] === 'discount-price-min' && pair[1].replace(/\D/g, '') === initialPriceMin) || (pair[0] === 'discount-price-max' && pair[1].replace(/\D/g, '') === initialPriceMax)) {
                    continue;
                }

                let fieldKey = pair[0];

                if (fieldKey.includes('[]')) {
                    fieldKey = fieldKey.slice(0, fieldKey.length - 2);
                    formData.append(`selected-filters[${fieldKey}][]`, pair[1]);
                } else {
                    formData.append(`selected-filters[${pair[0]}]`, pair[1]);
                }
            }
        }

        //  Validate all fields
        Object.keys(this.validationConfiguration).forEach(fieldName => {
            this.validateField(
                this.validationConfiguration[fieldName],
                fieldName,
                (fieldName.includes('[]') ? formData.getAll(fieldName) : formData.get(fieldName))
            ) ? errorCount++ : false;
        });

        // Send
        if (errorCount < 1) {
            if (this.functions.send) {
                this.functions.send(this.el)
            }

            this.sendTime = Date.now()
            this.el.classList.add('is-loading')

            const data = {
                'action': 'leadReceiver',
                'originUrl': window.location.href,
            };

            Array.from(formData.keys()).forEach(key => {
                if (key.includes('[]')) {
                    data[key.slice(0, key.length - 2)] = formData.getAll(key);
                } else {
                    data[key] = formData.get(key) === 'on' ? true : formData.get(key);
                }
            });

            axios({
                method: 'POST',
                headers: {'content-type': 'application/x-www-form-urlencoded'},
                url: '/wp/wp-admin/admin-ajax.php', 
                data: qs.stringify(data)
            }).then(response => {
                window.dataLayer.push({'event': 'form_sent', 'type': 'default', 'leadNumber': document.getElementById('leadId').value});
                window.dataLayer.push({'event': 'lead', 'report_to': 'sem'});
                var ifrm = document.createElement("iframe");
                ifrm.setAttribute("src", document.getElementById('confirm').value);
                ifrm.style.width = "0px";
                ifrm.style.height = "0px";
                document.getElementById('renderIframe').appendChild(ifrm);
                if (this.functions.success) {
                    this.functions.success(this.el)

                   
                }

                const timeDiff = Date.now() - this.sendTime
                setTimeout(() => {
                    this.el.classList.add('is-completed')
                    this.el.classList.remove('is-loading')
                }, (timeDiff < 200 ? Math.abs(timeDiff - 200) : 0))
            }).catch(error => {
                console.log(error);
                console.log(error.response.data)
            });
        } else {
            if (this.functions.error) {
                this.functions.error(this.el);
            }
        }
    }
}

export const formValidators = {
    showroom: {
        validate: (value) => {
            return !!value;
        },
        message: 'Musisz wybrać jeden z salonów!'
    },
    requiredText: {
        validate: (value) => {
            return value.trim().length;
        },
        message: 'Pole jest wymagane!'
    },
    email: {
        validate: (email) => {
            return email.includes('@')
        },
        message: 'Nieprawidłowy email!'
    },
    consent: {
        validate: (value) => {
            return !!value;
        },
        message: 'Ta zgoda jest wymagana!'
    },
    valueLength: {
        validate: (value) => {
            return value && value.length;
        },
        message: 'Pole nie może być puste!'
    }
}

export const defaultValidationConfiguration = {
    name: {
        validators: [formValidators.requiredText]
    },
    surname: {
        validators: [formValidators.requiredText]
    },
    phoneNumber: {
        validators: [formValidators.requiredText]
    },
    email: {
        validators: [
            formValidators.requiredText,
            formValidators.email
        ]
    },
    dataProcessingConsent: {
        validators: [formValidators.consent],
        triggerEvent: 'field-change'
    }
};

export const defaultFunctions = {
    error: (el) => {
        const firstField = el.querySelector('.has-error[data-field-name]')
        firstField.scrollIntoView({
            behavior: 'smooth',
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const el = document.querySelector('.js-form');
    if (el) {
        new Form(el, defaultValidationConfiguration, defaultFunctions);
        const leadNumber = Date.now() + Math.random()+'_vpl';
        document.getElementById('leadId').value = leadNumber;
        console.log(leadNumber);
        var frame = document.getElementById('confirm').value;
        frame = frame.replace('{{leadId}}',leadNumber);
        document.getElementById('confirm').value = frame;


    }
    
    document.getElementById('moreclick').addEventListener('click', () => {
        document.getElementById('more').removeAttribute('style');
        document.getElementById('more').classList.toggle('renderShow');
        document.getElementById('moreclick').style.display = 'none';
        document.getElementById('lessclick').style.display = 'block';
    })
    document.getElementById('lessclick').addEventListener('click', () => {
        document.getElementById('more').classList.toggle('renderShow');
        setTimeout(function() {
        document.getElementById('moreclick').style.display = 'block';
        document.getElementById('lessclick').style.display = 'none';
        document.getElementById('more').style.display = 'none';
    },300);

    })
});
